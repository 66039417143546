<body>
  <div class="preloader">
    <div class="loader">
      <div class="ytp-spinner">
        <div class="ytp-spinner-container">
          <div class="ytp-spinner-rotator">
            <div class="ytp-spinner-left">
              <div class="ytp-spinner-circle"></div>
            </div>
            <div class="ytp-spinner-right">
              <div class="ytp-spinner-circle"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section
    class="navbar-area"
    style="background: #0000000d; padding-bottom: 3px"
  >
    <div class="container">
      <div class="row d-flex justify-content-between">
        <div class="col-md-12">
          <nav class="navbar navbar-expand-lg">
            <a class="navbar-brand" href="#">
              <img
                src="../assets/images/flower.png"
                style="max-height: 80px"
                alt="Flower Bazar"
              />
            </a>

            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarTwo"
              aria-controls="navbarTwo"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="toggler-icon"></span>
              <span class="toggler-icon"></span>
              <span class="toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse sub-menu-bar" id="navbarTwo">
              <ul class="navbar-nav m-auto">
                <li class="nav-item active">
                  <a class="page-scroll" href="#home">home</a>
                </li>
                <li class="nav-item">
                  <a class="page-scroll" href="#services">Services</a>
                </li>
                <li class="nav-item">
                  <a class="page-scroll" href="#videoIntro">About</a>
                </li>
                <li class="nav-item">
                  <a class="page-scroll" href="#coontacts">Contact</a>
                </li>
              </ul>
            </div>
          </nav>
          <!-- navbar -->
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </section>

  <!--====== NAVBAR TWO PART ENDS ======-->

  <!--====== SLIDER PART START ======-->

  <section id="home" class="slider_area mu-header" style="min-height: 720px">
    <br />
    <br />
    <br />
    <br />
    <div
      class="row"
      style="width: 100%; position: absolute; bottom: 0px; margin-bottom: 216px"
    >
      <div class="col-md-7 col-xs-12"></div>
      <div class="col-md-5 item2">
        <img
          src="../assets/images/flower.png"
          alt="Flower Bazzer"
          class="home_logo"
        />
        <p
          style="
            color: #fff;
            margin-left: 5px;
            font-family: 'Open Sans', sans-serif;
            font-size: 15px;
            letter-spacing: 0.5px;
          "
        >
          The Best App of Flower Shops in UAE
        </p>
      </div>
    </div>
    <div
      class="row"
      style="width: 100%; position: absolute; bottom: 0px; margin-bottom: 100px"
    >
      <div class="col-md-7 col-sm-12 signup">
        <div class="item1 d-flex justify-content-center">
          <div class="align-items-center">
            <div class="input-group">
              <form class="input-group" action="post">
                <input
                style="max-width: 196px; height: 50px"
                type="email"
                class="form-control"
                placeholder="Your Email here"
                required="required"
                
                [(ngModel)]="subscription.email"

              />
              <span class="input-group-btn">
                <button class="btn btn-default" type="submit" (click)="addSubscription()">Sign up</button>
              </span>
              </form>
              
            </div>
            <!-- /input-group -->
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="item2" style="margin: auto">
          <div>
            <div class="mu-app-download-area">
              <h4 class="text-white"><span> Try for free</span></h4>
              <div class="d-flex justify-content-md-start justify-content-sm-center justify-content-center">
                <img
                src="../assets/images/AppLogo.png"
                alt="App"
                style="width: 394px"
                usemap="#workmap"
              />
              <map name="workmap">
                <area shape="rect" coords="1,1,185,54" alt="App Store" href="https://apps.apple.com/ae/app/flower-bazar-%D9%81%D9%84%D8%A7%D9%88%D8%B1-%D8%A8%D8%A7%D8%B2%D8%A7%D8%B1/id1521653628">
                <area shape="rect" coords="200,1,385,54" alt="Google Play" href="https://play.google.com/store/apps/details?id=flowerbazar.ae.apps">
              </map>
              </div>
             
              <!--<a class="mu-apple-btn" href="#"><i class="fa fa-apple"></i><span>apple store</span></a>
              <a class="mu-google-btn" href="#"><i class="fa fa-android"></i><span>google play</span></a>-->
              <!-- <a class="mu-windows-btn" href="#"><i class="fa fa-windows"></i><span>windows store</span></a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== SLIDER PART ENDS ======-->

  <!--====== FEATRES TWO PART START ======-->

  <section
    id="services"
    class="features-area"
    style="padding-top: 0px; padding-bottom: 0px"
  >
    <div class="mu-feature">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="mu-feature-area">
              <div class="mu-title-area">
                <h2 class="mu-title">About Our App</h2>
                <p>We love what we do & we do it with passion!</p>
              </div>

              <!-- Start Feature Content -->
              <div class="mu-feature-content">
                <div class="row">
                  <!--<div class="mu-feature-content-left">
                      <img class="mu-profile-img" src="assets/images/iphone-group.png"
                        alt="iphone Image">
                    </div>-->
                  <div class="col-md-3">
                    <div class="row">
                      <div class="col-12 order-sm-1">
                          <img
                        class="mu-profile-img"
                        src="assets/images/01.png"
                        alt="iphone Image"
                        />
                      </div>
                      <div class="col-12 order-sm-2">
                        <ul>
                          <li>- Search with All Cities</li>
                          <li>- Search with Occasion</li>
                          <li>- Compare the Quality and Prices</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="row">
                      <div class="col-12 order-sm-2 mt-sm-2 order-md-1">
                        <ul>
                          <li>- Deliver Options</li>
                          <li>- Deliver By Date</li>
                          <li>- Select Your prefer Times</li>
                        </ul>
                      </div>
                      <div class="col-12 order-sm-1 order-md-2">
                        <img
                        class="mu-profile-img"
                        src="assets/images/Apple iPhone 11 Pro Max Silver 002.png"
                        alt="iphone Image"
                        class="mt-sm-1 mt-md-6  mx-auto"
                         />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <img
                      class="mu-profile-img"
                      src="assets/images/02.png"
                      alt="iphone Image"
                    />
                    <ul>
                      <li>- Offers</li>
                      <li>- Filter by Low price , Kind, Quality Flowers</li>
                      <li>- Compare the Shops</li>
                    </ul>
                  </div>
                  <div class="col-md-3">
                    <div class="row">
                      <div class="col-12 order-sm-2 order-md-1">
                        <ul>
                          <li>- Easy to use</li>
                          <li>- Easy to pay</li>
                          <li>- Save your Time</li>
                        </ul>
                      </div>
                      <div class="col-12 order-sm-1 order-md-2">
                        <img
                        class="mu-profile-img"
                        src="assets/images/Apple iPhone 11 Pro Max Silver 003.png"
                        alt="iphone Image"
                        class="mt-sm-1 mt-md-6"
                      />

                      </div>
                    </div>
                    

                  </div>
                </div>
              </div>
              <!-- End Feature Content -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- container -->
  </section>

  

  

 

  <!--====== PRINICNG START ======-->

  <section id="contacts" class="pricing-area">
    <section id="videoIntro">
      <section class="text-center text-white">
        <h1>INTRO VIDEO</h1>
        <p>Full-screen intro with background image and video popup.</p>
        <a data-toggle="modal" data-target="#myModal" data-src="">
          <i class="fa fa-play-circle fa-5x"></i>
        </a>
        
      </section>
    </section>
    <section class="m-4 d-flex justify-content-center">
      <iframe class="rounded map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d698.5587164192076!2d54.36308126656041!3d24.49307166702095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e67e61e359f01%3A0xcb825a5ad7b29520!2sFlower%20Bazar!5e0!3m2!1sar!2sae!4v1607591399532!5m2!1sar!2sae" width="75%" height="400px" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
    </section>
    <div id="coontacts" class="contacts-contact">
      <div class="container">
        <div>
          <h2>Contacts Us</h2>
          <div class="row">
            <div class="col-12">
              <div class="cont">
                <div class="row">
                  <div class="col-xs-12 col-md-4 col-sm-4 text-center text-white pt-2">
                    <i class="fa fa-map-marker fa-3x" aria-hidden="true"></i>
                    <h4 class="ff-base">Address</h4>
                    <p class="text-white">
                      Deena Tower<br> 
                      Khalifa Street, Abu Dhabi,<br>
                      <strong>United Arab Emirates</strong>
                    </p>
                  </div>
                  <div class="col-xs-12 col-md-4 col-sm-4 text-center text-white pt-2">
                    <i class="fa fa-phone fa-3x" aria-hidden="true"></i>
                    <h4 class="ff-base">Call center</h4>
                    <p class="text-white">
                      Sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                    <p class="text-white"><strong>+971 2 449 4449</strong></p>
                    <p class="text-white"><strong>+971 56 293 5286</strong></p>
                  </div>
                  <div class="col-xs-12 col-md-4 col-sm-4 text-center text-white pt-2">
                    <i class="fa fa-envelope fa-3x" aria-hidden="true"></i>
                    <h4 class="ff-base">Electronic support</h4>
                    <p class="text-white">
                      Please feel free to write an email to us 
                    </p>
                    <ul class="list-unstyled text-white">
                      <li>info@flowerbazar.ae</li>
                      <li>cs@flowerbazar.ae</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-5">
              <form
                #contactForm="ngForm"
                class="form"
                id="contact-form"
                method="post"
              >
                <div class="controls">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="form-label" for="name"
                          >Your firstname *</label
                        >
                        <input
                          class="form-control"
                          type="text"
                          name="name"
                          id="name"
                          [(ngModel)]="subscriptionDetails.firstName"
                          placeholder="Enter your firstname"
                          required="required"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="form-label" for="surname"
                          >Your lastname *</label
                        >
                        <input
                          class="form-control"
                          type="text"
                          name="surname"
                          id="surname"
                          [(ngModel)]="subscriptionDetails.lastName"
                          placeholder="Enter your  lastname"
                          required="required"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="form-label" for="email">Your email *</label>
                    <input
                      class="form-control"
                      type="email"
                      name="email"
                      id="email"
                      [(ngModel)]="subscriptionDetails.email"
                      placeholder="Enter your  email"
                      required="required"
                    />
                  </div>
                  <div class="form-group">
                    <label class="form-label" for="message"
                      >Your message for us *</label
                    >
                    <textarea
                      class="form-control"
                      rows="4"
                      name="message"
                      id="message"
                      [(ngModel)]="subscriptionDetails.message"
                      placeholder="Enter your message"
                      required="required"
                    ></textarea>
                  </div>
                  <button [disabled]="!contactForm.valid" class="btn btn-outline-dark btn-lg" type="submit" (click)="sendEmail()">
                    Send message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div>
        <a class="d-flex justify-content-end fixed-bottom mb-4 mr-4" href="https://wa.me/+971562935286" target="_blank">
          <img src="assets/images/whatsapp.png" width="50px" height="50px" alt="">
        </a>
      </div>
    </div>
  </section>

  <!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      
      <div class="modal-body">

       <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="refresh()">
          <span aria-hidden="true">&times;</span>
        </button>        
        <!-- 16:9 aspect ratio -->
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" 
          src="https://www.youtube.com/embed/4ag6K_sY_r4" id="video" data-allow="autoplay; encrypted-media"  allowfullscreen>
          </iframe>
        </div>
        
        
      </div>

    </div>
  </div>
</div> 


</body>
